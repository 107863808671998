import I18n from 'I18n';
const createAlert = (path, type) => () => ({
  type,
  message: I18n.text(`alerts.${path}Message`),
  titleText: I18n.text(`alerts.${path}Title`)
});
export const getInstallExtensionSuccessAlert = createAlert('installExtensionSuccess', 'success');
export const getInstallExtensionFailureAlert = createAlert('installExtensionFailure', 'danger');
export const getDoNotDisturbEnableFailureAlert = createAlert('doNotDisturbEnableFailure', 'danger');
export const getDoNotDisturbDisableFailureAlert = createAlert('doNotDisturbDisableFailure', 'danger');
export const getMuteFailureAlert = createAlert('muteFailure', 'danger');