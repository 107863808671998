import { combineReducers } from 'redux';
// @ts-expect-error Untyped
import portalSettings from 'sales-clients-common/reducers/portalSettings';
import Auth from './Auth';
import Inbox from './Inbox';
import UI from './UI';
import Activities from './Activities';
import BackgroundActivitiesCheck from './BackgroundActivitiesCheck';
import DoNotDisturb from './DoNotDisturb';
import UserAttributes from './UserAttributes';
import Meetings from './Meetings';
import Tasks from './Tasks';
import Settings from './Settings';
import Overlay from './Overlay';
import AddOns from './AddOns';
export default combineReducers({
  auth: Auth,
  ui: UI,
  activities: Activities,
  backgroundActivitiesCheck: BackgroundActivitiesCheck,
  inbox: Inbox,
  doNotDisturb: DoNotDisturb,
  userAttributes: UserAttributes,
  meetings: Meetings,
  overlay: Overlay,
  tasks: Tasks,
  settings: Settings,
  portalSettings,
  addOns: AddOns
});