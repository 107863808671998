/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-unsafe-storage */

export default {
  get(key) {
    const value = localStorage.getItem(key);
    try {
      // Only parse if we got a string. If the value is falsy, just return it.
      if (value) {
        return JSON.parse(value);
      }
      return value;
    } catch (err) {
      return value;
    }
  },
  set(key, value) {
    localStorage.setItem(key, value);
  }
};