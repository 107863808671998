import { Record } from 'immutable';
import SourceTypes from 'activity-feed-ui/constants/SourceTypes';
import { getUniqueKey as getUniqueKeyUtil } from '../utils/activityOriginUtils';
const defaultActivityOriginDetailValues = {
  activityOriginId: '',
  activityOriginType: '',
  associatedCompanyId: null,
  avatarUrl: null,
  name: undefined,
  firstName: undefined,
  lastName: undefined,
  email: '',
  companyName: null,
  jobTitle: null,
  domain: null
};
const ActivityOriginDetailFactory = Record(defaultActivityOriginDetailValues, 'ActivityOriginDetail');
export default class ActivityOriginDetailRecord extends ActivityOriginDetailFactory {
  mergeWithContact(contact) {
    const vid = contact.get('vid');
    if (vid) {
      return this.merge({
        activityOriginId: `${vid}`,
        activityOriginType: SourceTypes.CONTACT,
        firstName: contact.getIn(['properties', 'firstname', 'value']),
        lastName: contact.getIn(['properties', 'lastname', 'value']),
        email: contact.getIn(['properties', 'email', 'value']),
        jobTitle: contact.getIn(['properties', 'jobtitle', 'value'])
      });
    }
    return this;
  }
  isContact() {
    return this.activityOriginType === SourceTypes.CONTACT;
  }
  getUniqueKey() {
    return getUniqueKeyUtil(this.activityOriginType, this.activityOriginId);
  }
}