import Raven from 'raven-js';
import identity from 'transmute/identity';
// @ts-expect-error Untyped
import createAction from 'flux-actions/createAction';
import postDoNotDisturb from 'activity-feed-ui/utils/postDoNotDisturb';
import AttributesApi from 'activity-feed-ui/api/AttributesApi';
import AttributeKeys from 'activity-feed-ui/constants/AttributeKeys';
import { FETCH_DO_NOT_DISTURB_SUCCESS, UPDATE_DO_NOT_DISTURB, UPDATE_DO_NOT_DISTURB_SUCCESS, UPDATE_DO_NOT_DISTURB_FAILURE } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
import { getDoNotDisturbEnableFailureAlert, getDoNotDisturbDisableFailureAlert } from 'activity-feed-ui/lib/Alerts';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import tracker from 'activity-feed-ui/tracking/tracker';
function valueFromApi(value) {
  const val = value || 'true';
  return val.toLowerCase() !== 'true';
}
function valueForApi(value) {
  return `${!value}`;
}
const fetchDoNotDisturbSuccess = createAction(FETCH_DO_NOT_DISTURB_SUCCESS, identity);
const updateDoNotDisturbSuccess = createAction(UPDATE_DO_NOT_DISTURB_SUCCESS, identity);
export const fetchDoNotDisturb = () => dispatch => {
  return AttributesApi.fetchAttributes({
    [AttributeKeys.DoNotDisturb]: 'false'
  }).then(attributes => {
    dispatch(fetchDoNotDisturbSuccess(valueFromApi(attributes.get(AttributeKeys.DoNotDisturb))));
  });
};
export const updateDoNotDisturb = doNotDisturb => dispatch => {
  const handleUpdateDoNotDisturbSuccess = response => {
    const value = valueFromApi(response);
    dispatch(updateDoNotDisturbSuccess(value));
    postDoNotDisturb(value);
    tracker.trackDoNotDisturb(value);
  };
  const handleUpdateDoNotDisturbFailure = err => {
    if (doNotDisturb) {
      FloatingAlertStore.addAlert(getDoNotDisturbDisableFailureAlert());
    } else {
      FloatingAlertStore.addAlert(getDoNotDisturbEnableFailureAlert());
    }
    dispatch({
      type: UPDATE_DO_NOT_DISTURB_FAILURE
    });
    Raven.captureMessage(`doNotDisturb error: ${err.status}`, {
      extra: {
        err
      }
    });
  };
  dispatch({
    type: UPDATE_DO_NOT_DISTURB
  });
  return AttributesApi.saveAttribute(AttributeKeys.DoNotDisturb, valueForApi(doNotDisturb)).then(handleUpdateDoNotDisturbSuccess, handleUpdateDoNotDisturbFailure);
};