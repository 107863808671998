import { css } from 'styled-components';
export const linkStyles = ({
  theme,
  disabled
}) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.color['text-primary-default'],
    left: `-${theme.space['300']}`,
    paddingInline: theme.space['300'],
    paddingBlock: theme.space['200'],
    position: 'relative',
    textDecoration: 'none',
    width: `calc(100% + ${theme.space['300']})`,
    cursor: disabled === true ? 'not-allowed' : 'pointer',
    transition: '0.15s all ease-out'
  };
};
export const activeStyles = css(["color:", ";background-color:", ";border-radius:", ";&::before{content:'';border-left:", " solid ", ";height:100%;left:0;position:absolute;top:0;border-radius:", ";}"], ({
  theme
}) => theme.color['text-primary-default'], ({
  theme
}) => theme.color['fill-secondary-subtle'], ({
  theme
}) => theme.borderRadius['100'], ({
  theme
}) => theme.borderWidth['300'], ({
  theme
}) => theme.color['fill-secondary-default'], ({
  theme
}) => theme.borderRadius['100']);
export const hoveredStyles = ({
  theme
}) => ({
  textDecoration: 'none',
  backgroundColor: theme.color['fill-secondary-subtle'],
  borderRadius: theme.borderRadius['100']
});
export const parentStyles = {
  userSelect: 'none'
};