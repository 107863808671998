import { FETCH_DO_NOT_DISTURB_SUCCESS, UPDATE_DO_NOT_DISTURB_SUCCESS } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
const initialState = false;
export default ((state = initialState, action) => {
  switch (action.type) {
    case FETCH_DO_NOT_DISTURB_SUCCESS:
      return action.payload;
    case UPDATE_DO_NOT_DISTURB_SUCCESS:
      return action.payload;
    default:
      return state;
  }
});