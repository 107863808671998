import identity from 'transmute/identity';
// @ts-expect-error Untyped
import createAction from 'flux-actions/createAction';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
import ContactsApi from 'activity-feed-ui/api/ContactsApi';
import { CONTACT_CREATE, CONTACT_CREATE_SUCCESS, CONTACT_CREATE_FAILURE } from 'activity-feed-ui/redux/actionTypes/ActionTypes';
import AddContactPrompt from 'activity-feed-ui/components/prompts/AddContactPrompt';
import I18n from 'I18n';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
const getCreateContactPayload = contactData => {
  const source = 'SALES_NOTIFICATIONS_UI';
  return Object.keys(contactData).map(property => {
    const value = contactData[property];
    return {
      value,
      property,
      source
    };
  });
};
const createContactStart = createAction(CONTACT_CREATE, identity);
const createContactSuccess = createAction(CONTACT_CREATE_SUCCESS, identity);
const createContactFailure = createAction(CONTACT_CREATE_FAILURE, identity);
const noop = () => {};
export const createContact = (origin, onSuccess = noop) => (dispatch, getState) => {
  AddContactPrompt({
    // @ts-expect-error TODO: mainRecipient does not exist on type UIDialogProps
    mainRecipient: origin
  }).then(contactData => {
    dispatch(createContactStart());
    const contactPayload = {
      properties: getCreateContactPayload(contactData)
    };
    ContactsApi.createContact(contactPayload).then(contact => {
      const oldBundle = getState().activities.activityBundles.get(origin.getUniqueKey());
      const newBundle = oldBundle.updateOriginWithContact(contact);
      dispatch(createContactSuccess({
        oldBundle,
        newBundle
      }));
      onSuccess();
      FloatingAlertStore.addAlert({
        titleText: I18n.text('alerts.contactCreatedSuccessTitle'),
        message: I18n.text('alerts.contactCreatedSuccessMessage', {
          email: contact.getIn(['properties', 'email', 'value'])
        }),
        type: 'success'
      });
    }).catch(err => {
      FloatingAlertStore.addAlert({
        message: I18n.text('errors.createContact'),
        titleText: I18n.text('errors.error'),
        type: 'danger'
      });
      dispatch(createContactFailure(err));
    });
  }).catch(rethrowError);
};